export const DEFAULT_PAGE_TITLE = 'IdeaList';
export const makePageTitle = (title?: string) =>
  title ? `${title} | IdeaList` : DEFAULT_PAGE_TITLE;

const IDEALIST_ITEM_PLACEHOLDER_STRINGS = [
  'Go to SoHo.',
  'Eat a burger on the main street.',
  'Check the coffee shop by Central Park.',
  'Cross the bridge and feel the wind.',
  'Go on a morning run by the beach.',
  "Go at the train station. There's a coworking space there.",
  'Check the statue in the main plaza.',
  'Ride the rental bikes.',
  'Book an electric scooter and ride the streets.',
  'Hike up the mountain and enjoy the view.',
  'Go in the woods and smell the fresh air.',
  'Check the mountain store on the 5th Avenue.',
  'Run on the F1 circuit!',
  'Rent a car and go to the Jungle.',
  'Walk the Golden gate bridge.',
  'Visit the Eiffel Tower.',
  "Go to the art museum. There's a nice exhibition there.",
  'Enjoy the sunset from the hidden beach.',
];

export const getRandomIdealistItemPlaceholder = () =>
  IDEALIST_ITEM_PLACEHOLDER_STRINGS[
    Math.floor(Math.random() * IDEALIST_ITEM_PLACEHOLDER_STRINGS.length)
  ];

export const pickAtPositionOrCircle = (array: string[], pos: number) => {
  return array[pos % array.length];
};

export const generatePlaceHolderArray = (count: number) =>
  Array.from(Array(count).keys()).map(getRandomIdealistItemPlaceholder);
