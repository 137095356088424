export const MAIN_PAGE_IMAGE =
  'https://images.unsplash.com/photo-1500530855697-b586d89ba3ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80';

export const LOGO_IMAGE = '/images/logo/logo.svg';
export const LOGO_WHITE_IMAGE = '/images/logo/logo-white.svg';
export const LOGO_DEFAULT_SIZE = {
  width: 75,
  height: 75,
};

export const NOT_FOUND_IMAGE = '/images/404/404.svg';
export const GOOGLE_AUTH_IMAGE = '/images/auth/google_simple_g.png';

// blog
export const BLOG_COMPOUNDING_HABITS_101 = '/images/content/blog/101-365.jpg';
export const BLOG_COMPOUNDING_HABITS_MARGINAL_GAINS = '/images/content/blog/marginal-gains-700.jpg';

export const PROFILE_PICTURE_FEED_SIZE = 40; // same for width and height
export const PROFILE_PICTURE_NAVIGATION_ITEM_SIZE = 28; // same for width and height
export const PROFILE_PICTURE_PROFILE_SIZE = 100; // same for width and height
export const PROFILE_PICTURE_TOP_USERS_SIZE = 60; // same for width and height

export const IDEALIST_PLACEHOLDER_IMAGE =
  'https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80';
export const PROFILE_MAP_COVERAGE_PLACEHOLDER = '/images/content/map-with-pins.svg';

export const IDEALIST_IMAGE_UPLOAD_MAX_IMAGES_COUNT = 50;
